/* Background */
$background-00: #f3f1ef;
$background-01: #e8e4df;
$background-02: #ded9d2;
$background-03: #7a7370;

/* COLORS V2 */
/* Backgrounds */
$bg-00: #fbfbf9; // ex 100, bg-00 in tokens
$bg-01: #f4f4f1; // ex 200, bg-01 in tokens
$bg-02: #e4e3dd; // ex-300, bg-02 in tokens, apparently used nowhere ?
$bg-03: #dad9d0; // ex-400, bg-03 in tokens
$bg-inverted: #1c2f3f; // ex-500, bg-inverted in tokens

/* Borders */
$divider: rgb(218, 217, 208);

/* Primary */
$primary-main: #3081db;
$primaryLight-main: #dfecfb;
$primaryLight-contrastText: #3081db;

/* Neutral */
$neutral-main: #7a796e;
$neutral-contrastText: #fbfbf9;

$neutralLight-main: #fbfbf9;
$neutralLight-dark: #f4f4f1;
$neutralLight-contrastText: #262522;

/* Secondary */
$secondaryLight-main: #e3e6e8;
$secondaryLight-contrastText: #f1f2f3;

/* Success */
$success-main: #297c40;
$success-contrastText: #f5f9f6;
$successLight-main: #dfece2;
$successLight-contrastText: #297c40;
/* Warning */
$warning-main: #bc5f06;
$warning-contrastText: #fcf8f3;
$warningLight-main: #f6e5d5;
$warningLight-contrastText: #bc5f06;
/* Error */
$error-main: #a40d00;
$error-contrastText: #fbf4f3;
$errorLight-main: #f3dbd8;
$errorLight-contrastText: #a40d00;

/* Texts */
$text-primary: #262522;
$text-secondary: #7a796e;
$text-disabled: #363645;

/* Neutral - Main */
$neutral-main-text-primary: #1b2833;
$neutral-main-text-secondary: #485d70;
$neutral-main-text-tertiary: #7c8a9c;
$neutral-main-text-quaternary: #b6bcc3;
$neutral-main-default: #ffffff;
$neutral-main-hover: #f7f7f7;
$neutral-main-pressed: #f0f0f0;
$neutral-main-border: #ded9d2;
$neutral-main-border-focus: #e8e4df;

/* Neutral - Variant 01 */
$neutral-variant-default: alpha(#ccc1b3, 0.2);
$neutral-variant-text: #5d6a7b;

/* Primary - Main */
$primary-main-default: #3388e7;
$primary-main-text: #3388e7;
$primary-main-text-invert: #ffffff;
$primary-main-hover: #31475a;
$primary-main-pressed: #213b52;

/* Primary - Variant 01 */
$primary-variant-default: #3f505e;
$primary-variant-text-secondary-invert: #b6bcc3;
$primary-variant-text-invert: #ffffff;
$primary-variant-hover: #616f7b;
$primary-variant-pressed: #1c2f3f;

/* Semantic - Warning */
$warning-default: #f0e2d2;
$warning-hover: #f5ebe0;
$warning-pressed: #ebd8c2;
$warning-text-invert: #cc6d00;
$warning-text: #df8620;
$warning-border: #ddc8b0;

/* Semantic - Danger */
$danger-default: #edd8d0;
$danger-hover: #f1e0da;
$danger-pressed: #e6c8bd;
$danger-text-secondary: #812100;
$danger-text-invert: #c84011;
$danger-text: #c84011;
$danger-border: #d1ac9f;

/* Semantic - Success */
$success-default: #e0ecea;
$success-hover: adjust-color(alpha(#00adb5, 0.14), $lightness: 0.8);
$success-text-invert: #009299;
$success-text: #00adb5;
$success-pressed: #d9e8e5;
$success-border: #c0d8d4;
