.preferences-profile__img {
  margin-right: $spacing-4;
  object-fit: cover;
  box-sizing: content-box;
  width: 96px;
  height: 96px;
  font-size: $fts-large;
  border: 2px solid $blue-200;
}

.preferences-profile__heading {
  background: $white-secondary;

  nav {
    white-space: nowrap;

    a,
    li {
      text-decoration: none;
      padding-bottom: $spacing-2;
    }

    .is-active {
      color: $black-secondary;
      padding: $spacing-2 0;
      cursor: pointer;
      border-bottom: 2px solid $blue;
    }
  }

  .sidebar__backlink {
    position: relative;
    display: block;
    width: fit-content;
    cursor: pointer;
    transition: color 0.3s $timing-cubic;
    text-decoration: none;

    &:hover {
      color: $blue-600;
    }
  }

  .icon-arrow-left {
    position: relative;
    top: -1px;
  }
}

.organization__logo {
  height: 60px;
  margin-right: $spacing-5;
}

.preferences-signature {
  height: 80px;
}
