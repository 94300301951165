.card {
  width: 100%;
  padding: $spacing-3;
  background: $white-tertiary;
  color: $blue-400;
  border-radius: $radius;
}

.card--success {
  background: rgba(0, 173, 181, 0.08);
  color: $turquoise;
}

.card--warning {
  background: rgba(200, 64, 17, 0.08);
  color: $red;
}
