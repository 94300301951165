.box {
  position: relative;
  overflow: hidden;
  box-shadow: $shadow1;
  border-radius: $radius;
  transition: box-shadow 0.3s $timing-cubic;
  background-color: $white;
}

.box--padding {
  padding: $spacing-5 $spacing-6;
}

a.box {
  text-decoration: none;
  color: inherit;
  &:hover {
    box-shadow: $shadow2;
  }
}
