.search-input-lg {
  position: relative;
}

.search-input-lg__input {
  width: 100%;
  font-family: $ftf-default;
  font-size: $fts-small;
  line-height: normal;
  border: 1px solid $white-quaternary;
  border-radius: $radius-small;
  -webkit-appearance: none;
  transition: border-color 0.2s $timing-cubic;
  padding: 13px $spacing-2;

  &:focus,
  &:hover {
    border-color: $blue-200;
    outline: 0;
  }

  &::placeholder {
    color: $blue-200;
  }

  &:disabled {
    color: $white-quaternary;
    background-color: $white-secondary;
  }
}

.search-input-lg__label {
  display: block;
}

.search-input-lg__icon {
  color: $blue-200;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.search-input-lg__error {
  position: absolute;
  bottom: -$spacing-1;
  top: calc(100% + #{$spacing-1});
}

.search-input-lg--number {
  .search-input-lg__input {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.search-input-lg--icon-left {
  .search-input-lg__input {
    padding-left: $spacing-2 * 3;
  }

  .search-input-lg__icon {
    left: $spacing-2;
  }
}

.search-input-lg--icon-right {
  .search-input-lg__input {
    padding-right: $spacing-2 * 3;
  }

  .search-input-lg__icon {
    right: $spacing-2;
  }
}
