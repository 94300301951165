$margin: 0, $spacing-1, $spacing-2, $spacing-3, $spacing-4, $spacing-5,
  $spacing-6, $spacing-7, $spacing-8, $spacing-9, $spacing-10, $spacing-11;

$margin-h: 0, 4px, 8px, 16px, 20px, 24px, 32px, 40px, 48px, 56px, 64px;

@for $i from 1 through length($margin-h) {
  $size: #{nth($margin-h, $i)};
  $scale: #{$i - 1};

  .mr-#{$scale} {
    margin-right: #{$size};
  }
  .ml-#{$scale} {
    margin-left: #{$size};
  }
}

@for $i from 1 through length($margin) {
  $size: #{nth($margin, $i)};
  $scale: #{$i - 1};

  .mt-#{$scale} {
    margin-top: #{$size};
  }
  .mb-#{$scale} {
    margin-bottom: #{$size};
  }
}

@media (min-width: $grid-breakpoints-md) {
  @for $i from 1 through length($margin) {
    $size: #{nth($margin, $i)};
    $scale: #{$i - 1};

    .mt-md-#{$scale} {
      margin-top: #{$size};
    }
    .mb-md-#{$scale} {
      margin-bottom: #{$size};
    }
  }
}

@media (min-width: $grid-breakpoints-lg) {
  @for $i from 1 through length($margin) {
    $size: #{nth($margin, $i)};
    $scale: #{$i - 1};

    .mt-lg-#{$scale} {
      margin-top: #{$size};
    }
    .mb-lg-#{$scale} {
      margin-bottom: #{$size};
    }
  }
}
