hr,
.separator {
  border: 0;
  border-bottom: 2px solid $black;
}

.separator--dark {
  border-bottom-color: $dark-border;
}

.separator--light {
  border-bottom: 1px solid $white-tertiary;
}

.separator--thin {
  border-bottom-width: 1px;
}
