.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .table + & {
    margin-top: $spacing-6;
  }

  .btn {
    padding: 0;
    justify-content: center;
    min-width: 44px;
    width: 44px;
  }
}

.pagination__number {
  li {
    display: inline-block;
    margin: 0 $spacing-1;
    color: rgba($black, 0.24);
  }

  a {
    color: rgba($black, 0.24);
    text-decoration: none;
    padding: 4px;

    &:hover {
      color: $black-secondary;
    }
    &.is-active {
      color: $color-primary;
    }
  }
}

@media (min-width: $grid-breakpoints-md) {
  .pagination {
    justify-content: center;
  }
  .pagination__number {
    margin: 0 $spacing-3;
  }
}
