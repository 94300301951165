.link {
  color: currentColor;
  text-decoration: underline;
  cursor: pointer;
  transition: all 0.3s $timing-cubic;
  font-weight: 500;
  &:hover {
    color: $black;
  }
}
.link-chevron {
  display: block;
  font-weight: 500;
  text-decoration: none;
  color: $blue-400;
  cursor: pointer;
  outline: none;

  &:hover,
  &:focus {
    text-decoration: none;
    color: $black;
  }

  i {
    font-size: $fts-default;
    vertical-align: text-bottom;
    margin-left: $spacing-1;
  }
}

.link-chevron--right {
  i {
    float: right;
    line-height: 24px; // Link line-height
  }
}

.link-chevron--left {
  i {
    margin-right: $spacing-1;
    margin-left: 0;
  }
}

.link-icon {
  display: inline-block;
  width: 48px;
  height: 48px;
  line-height: 44px;
  text-align: center;
  border: 2px solid $color-primary;
  background-color: rgba($color-primary, 0.16);
  border-radius: 100%;
  cursor: pointer;

  &:hover {
    background-color: rgba($color-primary, 0.64);
  }

  i {
    vertical-align: middle;
  }
}

.link-icon--white {
  background-color: $white;
  border-color: $white;
  color: $color-primary;
  box-shadow: 0 3px 8px rgba(51, 51, 51, 0.08);
}

.link-icon--saved {
  &:hover:not(.is-active),
  &.is-active {
    cursor: pointer;
    i {
      &:before {
        content: '\e904';
        color: $blue;
      }
    }
  }
}

.link-copy {
  background-color: $white-secondary;
  border-radius: $radius-small;
  white-space: nowrap;

  input {
    height: 48px;
    line-height: 48px;
    width: 100%;
    padding: 0 12px;
    border: none;
    color: $blue-400;
    background-color: transparent;
    outline: none;
  }
}

.link-copy--white {
  background-color: $white;
}

.link-assets {
  text-decoration: none;
  position: relative;
  display: inline-flex;
  width: 100%;
  align-items: center;
  background: $white;
  padding-left: $spacing-2;
  border-radius: $radius;
  border: 1px solid $white-tertiary;
  height: 60px;
  transition: box-shadow 0.3s $timing-cubic;

  &:hover {
    text-decoration: none;
    box-shadow: $shadow2;
  }

  .loader-small {
    position: absolute;
    left: $spacing-2;
  }
}

.link-assets--loading {
  padding-left: $spacing-6;
}

.link-assets__remove {
  position: absolute;
  right: $spacing-2;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $white-secondary;
  transition: background-color 0.3s $timing-cubic;
  z-index: 2;
  &:hover {
    background-color: $white-tertiary;
  }
  i {
    position: relative;
    top: initial;
    right: initial;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 20px;
    cursor: pointer;
  }
}
