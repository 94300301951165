.dropzone {
  width: 100%;
  border: 1px dashed $neutral-main-border;
  min-height: 88px;
  background: $white-secondary;
  border-radius: $radius-small;
  padding: $spacing-3 $spacing-2;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.is-disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }

  &.dropzone__loading,
  &.dropzone__file-recap {
    padding: 48px;
    margin-top: 16px;

    .input {
      width: 80%;

      .icon-alert-circle {
        color: $danger-text;
      }

      .icon-file-text {
        color: $neutral-main-text-tertiary;
      }
    }

    input {
      @include body-m-medium;
      color: $neutral-main-text-tertiary;
      border: 1px solid $neutral-main-border;
      background: $neutral-main-default;
    }
  }

  .dropzone__error-message {
    @include body-m-regular;
    width: 80%;
    text-align: left;
    color: $danger-text;
  }

  .dropzone__message {
    @include body-m-regular;
    width: 80%;
    text-align: center;
    color: $text-primary;
  }
}

.dropzone__logo {
  border-radius: $radius;
  border: 1px solid $white-quaternary;
  background: $white;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 $spacing-1 $spacing-3;

  img {
    margin-bottom: 0;
  }
}

.dropzone__loading-title {
  @include body-l-regular;
  color: $neutral-main-text-secondary;
  margin-top: 16px;
}

.dropzone__loading-file {
  @include body-m-regular;
  color: $neutral-main-text-tertiary;
  margin-top: 4px;
}
