.img-event-none {
  user-select: none;
  pointer-events: none;
}

.img-circle {
  border-radius: 50%;
  overflow: hidden;
  pointer-events: none;
}

.img-placeholder {
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: $fts-xs;
  font-weight: 700;
  color: $color-primary-focus;
  background: $white-tertiary;
  text-transform: uppercase;
  text-decoration: none;

  &--big {
    margin-right: $spacing-4;
    object-fit: cover;
    box-sizing: content-box;
    width: 96px;
    height: 96px;
    font-size: $fts-large;
    border: 2px solid $blue-200;
  }

  &--medium {
    width: 48px;
    height: 48px;
  }
}
