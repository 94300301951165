.body--modal {
  &::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }

  &:before {
    content: '';
    cursor: pointer;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($black, 0.5);
    z-index: 1201;
  }
}

.modal {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1202;
  -webkit-overflow-scrolling: touch;

  .modal__background {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    cursor: pointer;
  }

  .modal__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    max-width: 750px;
    // min-height: 40vh;
    display: flex;
    flex-direction: column;
    background-color: $white;
    padding: $spacing-4;
    border-radius: $radius;
    z-index: 10;
    cursor: initial;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  }

  .modal__close {
    position: absolute;
    top: $spacing-3;
    right: $spacing-3;
    background: $white-secondary;
    border-radius: 50%;
    transition: background-color 0.3s $timing-cubic;
    cursor: pointer;
    &:hover {
      background-color: $white-tertiary;
    }
  }

  .modal__title {
    @include title-h2b;
    color: $neutral-main-text-primary;
  }

  .modal__step {
    @include body-l-medium;
    color: $neutral-main-text-quaternary;
    margin-bottom: $spacing-2;
  }

  // .modal__link {
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  //   @include button-s;
  //   color: $neutral-main-text-secondary;

  //   &.modal__link-disabled {
  //     opacity: 0.5;
  //     cursor: none;
  //     pointer-events: none;
  //   }
  // }

  .modal__header {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: $spacing-2;
    margin-bottom: $spacing-3;

    .modal__icon-success {
      margin-bottom: 8px;
      width: 52px;
      height: 52px;
      border-radius: 2px;
      background-color: $success-default;
      text-align: center;

      .icon-check-circle {
        color: $success-text-invert;
        font-size: 24px;
        line-height: 50px;
      }
    }

    .modal__subtitle {
      @include body-xl-medium;
      color: $neutral-main-text-primary;
      margin-bottom: 4px;
    }

    .modal__baseline {
      @include body-m-regular;
      color: $neutral-main-text-tertiary;
    }
  }

  .modal__commission {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  @include custom-commission-item('modal__commission-item', 0);
}

.modal-upload-docket .modal__content {
  min-width: 800px;
}

.modal--confirm .input.has-error .input__error {
  position: static;
  .inline-fields-wrapper > div {
    display: none;
    width: 100%;
  }
}

.modal-create-signature__canvas {
  border: 2px solid $white-quaternary;
}

.modal-create-signature__confirm {
  width: fit-content;
  margin-left: auto;
  cursor: pointer;
}

.inline-fields-wrapper > div {
  width: 100%;
}
