.section {
  position: relative;
  padding-top: $spacing-6;
  padding-bottom: $spacing-6;

  .section__link {
    &:first-child {
      display: none;
    }
  }
}

.section-white {
  background-color: $white;
}
.section-black {
  background-color: $color-secondary;
  color: $white;
}

@media (min-width: $grid-breakpoints-md) {
  .section {
    padding-bottom: $spacing-7;
    .section__link {
      &:first-child {
        display: block;
      }
      &:last-child {
        display: none;
      }
    }
  }
}
@media (min-width: $grid-breakpoints-lg) {
  .section {
    padding-top: $spacing-10;
    padding-bottom: $spacing-10;
  }
  .section--top {
    padding-top: 112px;
  }
}
