@charset 'UTF-8';

@import 'base/variable';
@import 'base/fonts';
@import 'base/grid';
@import 'base/responsive';
@import 'base/reset';

@import 'uikit/colors';
@import 'uikit/text';
@import 'uikit/grid';

@import 'object/margin';
@import 'object/typography';
@import 'object/icon';
@import 'object/button';
@import 'object/link';
@import 'object/label';
@import 'object/box';
@import 'object/page';
@import 'object/section';
@import 'object/image';
@import 'object/table';
@import 'object/separator';
@import 'object/alignement';
@import 'object/form/input';
@import 'object/form/search-input-lg';
@import 'object/form/input-upload';
@import 'object/form/select';
@import 'object/form/selection-control';
@import 'object/tooltip';
@import 'object/filter-dropdown';
@import 'object/dropzone';
@import 'object/expansion-panel';

@import 'component/ace';
@import 'component/commission-item';
@import 'component/pagination';
@import 'component/navbar';
@import 'component/loader';
@import 'component/card';
@import 'component/modal';
@import 'component/sidebar';
@import 'component/heading';
@import 'component/card-sidebar';
@import 'component/card-reporting';
@import 'component/sidebar-profile';
@import 'component/preferences';
@import 'component/filters-commission';
@import 'component/commission';
@import 'component/commissions';
@import 'component/tag';

.test-anim {
  animation: rotate-light 20s linear infinite;
  position: absolute;
  left: 50%;
  top: 250px;
}

@keyframes rotate-light {
  0% {
    transform: translate(-50%, -50%);
  }
  25% {
    transform: translate(0%, -50%);
  }
  50% {
    transform: translate(0%, 0%);
  }
  75% {
    transform: translate(-50%, 0%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}
