.page {
  width: 100%;
  min-height: 100vh;
}

.page__content {
  min-height: 100vh;
}

.page--v-center {
  display: flex;
  align-items: center;
}

.page--black-secondary {
  background: $black-secondary;
  color: $white;
}

.page__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
  z-index: 0;
  svg {
    width: 100%;
  }

  &.page__background--animated {
    &:before {
      z-index: 0;
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 200%;
      height: 200%;
      transform: translate(-50%, -50%);
      animation: rotate-light 60s linear infinite 6s;
    }

    .shape__animated {
      z-index: 1;
      position: absolute;
      opacity: 0.5;

      &#rect1 {
        left: 10vw;
        top: 20vh;
        border-radius: 150px;
        width: 200px;
        height: 53px;
      }

      &#rect2 {
        right: 15vw;
        bottom: 50vh;
        border-radius: 150px;
        width: 350px;
        height: 98px;
      }

      &#bubble {
        left: 15vw;
        bottom: 15vh;
        width: 250px;
        height: 250px;
        border-radius: 50%;
      }
    }
  }
}

.page--black {
  background: $black-secondary;

  .page__background--animated {
    &:before {
      background: radial-gradient(
        77.57% 70.7% at 50% 49.98%,
        #274258 0%,
        #1c2f3f 25%,
        #162531 66.5%
      );
    }

    #rect1 {
      transform: rotate(-75deg);
      animation: rotate-rect1-shadows--dark 60s linear infinite, rotate-shapes 30s infinite;
      box-shadow: 11px 11px 28px 0px rgba(8, 13, 18, 0.5), 1px 1px 4px 0px #304f69 inset,
        -1px -1px 2px 0px rgba(8, 13, 18, 0.5) inset;
      background-color: rgba(#fff, 0.01);
    }
    #rect2 {
      transform: rotate(-75deg);
      animation: rotate-rect2-shadows--dark 60s linear infinite, rotate-shapes 30s infinite 10s;
      box-shadow: 11px 11px 50px 0px rgba(8, 13, 18, 0.1),
        1px 1px 1px 0px rgba(48, 79, 105, 0.1) inset, -1px -1px 2px 0px rgba(8, 13, 18, 0.1) inset;
      background-color: rgba(#fff, 0.01);
    }
    #bubble {
      transform: scale(1);
      animation: rotate-bubble-shadows--dark 60s linear infinite, scale-shapes 30s infinite;
      box-shadow: 20px 20px 50px 0px rgba(8, 13, 18, 0.3),
        8px 8px 10px 0px rgba(48, 79, 105, 0.5) inset, -8px -8px 10px 0px rgba(8, 13, 18, 0.4) inset;
      background-color: rgba(#fff, 0.01);
    }
  }
}

.page--white {
  background: $white;

  .page__background--animated {
    &:before {
      background: radial-gradient(77.57% 70.7% at 50% 49.98%, #fbfbf9 0%, #e4e3dd 66.5%);
    }

    #rect1 {
      transform: rotate(-75deg);
      animation: rotate-rect1-shadows--white 60s linear infinite, rotate-shapes 30s infinite;
      box-shadow: 11px 11px 28px 0px rgba(56, 54, 46, 0.3), 1px 1px 4px 0px #fff inset,
        -1px -1px 2px 0px rgba(56, 54, 46, 0.3) inset;
      background-color: rgba(#fff, 0.01);
    }
    #rect2 {
      transform: rotate(-75deg);
      animation: rotate-rect2-shadows--white 60s linear infinite, rotate-shapes 30s infinite 10s;
      box-shadow: 11px 11px 50px 0px rgba(56, 54, 46, 0.1),
        1px 1px 1px 0px rgba(255, 255, 255, 0.1) inset,
        -1px -1px 2px 0px rgba(56, 54, 46, 0.1) inset;
      background-color: rgba(#fff, 0.01);
    }
    #bubble {
      transform: scale(1);
      animation: rotate-bubble-shadows--white 60s linear infinite, scale-shapes 30s infinite;
      box-shadow: 20px 20px 50px 0px rgba(56, 54, 46, 0.2),
        8px 8px 10px 0px rgba(255, 255, 255, 0.5) inset,
        -8px -8px 10px 0px rgba(56, 54, 46, 0.2) inset;
      background-color: rgba(#fff, 0.01);
    }
  }
}

@keyframes rotate-light {
  0% {
    transform: translate(-50%, -50%);
  }
  25% {
    transform: translate(0%, -50%);
  }
  50% {
    transform: translate(0%, 0%);
  }
  75% {
    transform: translate(-50%, 0%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

@keyframes rotate-rect1-shadows--dark {
  0% {
    box-shadow: 11px 11px 28px 0px rgba(8, 13, 18, 0.5), 3px 3px 5px 0px #304f69 inset,
      -3px -3px 5px 0px rgba(8, 13, 18, 0.5) inset;
    //opacity: 1;
  }
  25% {
    box-shadow: -15px 5px 28px 5px rgba(8, 13, 18, 0.3),
      -4px 0px 5px 0px rgba(48, 79, 105, 0.5) inset, 3px 0px 5px 0px rgba(8, 13, 18, 0.3) inset;
    //opacity: 0.5;
  }
  50% {
    box-shadow: -11px -11px 28px 5px rgba(8, 13, 18, 0.1),
      -2px -2px 5px 0px rgba(48, 79, 105, 0.25) inset, 2px 2px 5px 0px rgba(8, 13, 18, 0.1) inset;
    //opacity: 0.2;
  }
  75% {
    box-shadow: 5px -15px 28px 5px rgba(8, 13, 18, 0.3),
      0px -6px 5px 0px rgba(40, 66, 88, 0.5) inset, 0px 2px 5px 0px rgba(8, 13, 18, 0.3) inset;
    //opacity: 0.5;
  }
  100% {
    box-shadow: 11px 11px 28px 0px rgba(8, 13, 18, 0.5), 3px 3px 5px 0px #304f69 inset,
      -3px -3px 5px 0px rgba(8, 13, 18, 0.5) inset;
    //opacity: 1;
  }
}

@keyframes rotate-rect2-shadows--dark {
  0% {
    box-shadow: 11px 11px 50px 0px rgba(8, 13, 18, 0.1),
      1px 1px 1px 0px rgba(48, 79, 105, 0.1) inset, -1px -1px 2px 0px rgba(8, 13, 18, 0.1) inset;
    //opacity: 0.2;
  }
  25% {
    box-shadow: -5px 30px 50px 0px rgba(8, 13, 18, 0.5), -2px 10px 5px 0px #284258 inset,
      1px -2px 5px 0px rgba(8, 13, 18, 0.5) inset;
    //opacity: 1;
  }
  50% {
    box-shadow: -11px -30px 50px 0px rgba(8, 13, 18, 0.5), 0px -10px 5px 0px #304f69 inset,
      5px 5px 5px 0px rgba(8, 13, 18, 0.5) inset;
    //opacity: 1;
  }
  75% {
    box-shadow: 15px 0px 28px 5px rgba(8, 13, 18, 0.3), 5px -5px 5px 0px rgba(40, 66, 88, 0.5) inset,
      -5px 1px 5px 0px rgba(8, 13, 18, 0.3) inset;
    //opacity: 0.5;
  }
  100% {
    box-shadow: 11px 11px 50px 0px rgba(8, 13, 18, 0.1),
      1px 1px 1px 0px rgba(48, 79, 105, 0.1) inset, -1px -1px 2px 0px rgba(8, 13, 18, 0.1) inset;
    //opacity: 0.2;
  }
}

@keyframes rotate-bubble-shadows--dark {
  0% {
    box-shadow: 20px 20px 50px 0px rgba(8, 13, 18, 0.3),
      8px 8px 10px 0px rgba(48, 79, 105, 0.5) inset, -8px -8px 10px 0px rgba(8, 13, 18, 0.4) inset;
    //opacity: 0.5;
  }
  25% {
    box-shadow: -11px 11px 50px 0px rgba(8, 13, 18, 0.1),
      -8px 8px 10px 0px rgba(48, 79, 105, 0.25) inset, 8px -8px 10px 0px rgba(8, 13, 18, 0.1) inset;
    //opacity: 0.2;
  }
  50% {
    box-shadow: -20px -20px 50px 0px rgba(8, 13, 18, 0.3),
      -10px -10px 10px 0px rgba(48, 79, 105, 0.5) inset,
      10px 10px 10px 0px rgba(8, 13, 18, 0.4) inset;
    //opacity: 0.5;
  }
  75% {
    box-shadow: 20px -20px 50px 0px rgba(8, 13, 18, 0.5), 10px -10px 10px 0px #304f69 inset,
      -10px 10px 10px 0px rgba(8, 13, 18, 0.5) inset;
    //opacity: 1;
  }
  100% {
    box-shadow: 20px 20px 50px 0px rgba(8, 13, 18, 0.3),
      8px 8px 10px 0px rgba(48, 79, 105, 0.5) inset, -8px -8px 10px 0px rgba(8, 13, 18, 0.4) inset;
    //opacity: 0.5;
  }
}

@keyframes rotate-rect1-shadows--white {
  0% {
    box-shadow: 11px 11px 28px 0px rgba(56, 54, 46, 0.3), 3px 3px 5px 0px #fff inset,
      -3px -3px 5px 0px rgba(56, 54, 46, 0.3) inset;
  }
  25% {
    box-shadow: -15px 5px 28px 5px rgba(56, 54, 46, 0.2),
      -4px 0px 5px 0px rgba(255, 255, 255, 0.5) inset, 3px 0px 5px 0px rgba(56, 54, 46, 0.2) inset;
  }
  50% {
    box-shadow: -11px -11px 28px 5px rgba(56, 54, 46, 0.1),
      -2px -2px 5px 0px rgba(255, 255, 255, 0.25) inset, 2px 2px 5px 0px rgba(56, 54, 46, 0.1) inset;
  }
  75% {
    box-shadow: 5px -15px 28px 5px rgba(56, 54, 46, 0.2),
      0px -6px 5px 0px rgba(255, 255, 255, 0.5) inset, 0px 2px 5px 0px rgba(56, 54, 46, 0.2) inset;
  }
  100% {
    box-shadow: 11px 11px 28px 0px rgba(56, 54, 46, 0.3), 3px 3px 5px 0px #fff inset,
      -3px -3px 5px 0px rgba(56, 54, 46, 0.3) inset;
  }
}

@keyframes rotate-rect2-shadows--white {
  0% {
    box-shadow: 11px 11px 50px 0px rgba(56, 54, 46, 0.1),
      5px 5px 5px 0px rgba(255, 255, 255, 0.1) inset, -5px -5px 5px 0px rgba(56, 54, 46, 0.1) inset;
  }
  25% {
    box-shadow: -5px 30px 50px 0px rgba(56, 54, 46, 0.3), -2px 10px 5px 0px #fff inset,
      1px -2px 5px 0px rgba(56, 54, 46, 0.3) inset;
  }
  50% {
    box-shadow: -11px -30px 50px 0px rgba(56, 54, 46, 0.3), 0px -10px 5px 0px #fff inset,
      5px 5px 5px 0px rgba(56, 54, 46, 0.3) inset;
  }
  75% {
    box-shadow: 15px 0px 28px 5px rgba(56, 54, 46, 0.1),
      5px -5px 5px 0px rgba(255, 255, 255, 0.5) inset, -5px 1px 5px 0px rgba(56, 54, 46, 0.2) inset;
  }
  100% {
    box-shadow: 11px 11px 50px 0px rgba(56, 54, 46, 0.1),
      5px 5px 5px 0px rgba(255, 255, 255, 0.1) inset, -5px -5px 5px 0px rgba(56, 54, 46, 0.1) inset;
  }
}

@keyframes rotate-bubble-shadows--white {
  0% {
    box-shadow: 20px 20px 50px 0px rgba(56, 54, 46, 0.2),
      8px 8px 10px 0px rgba(255, 255, 255, 0.5) inset,
      -8px -8px 10px 0px rgba(56, 54, 46, 0.2) inset;
  }
  25% {
    box-shadow: -11px 11px 50px 0px rgba(56, 54, 46, 0.1),
      -8px 8px 10px 0px rgba(255, 255, 255, 0.25) inset,
      8px -8px 10px 0px rgba(56, 54, 46, 0.1) inset;
  }
  50% {
    box-shadow: -20px -20px 50px 0px rgba(56, 54, 46, 0.2),
      -10px -10px 10px 0px rgba(255, 255, 255, 0.5) inset,
      10px 10px 10px 0px rgba(56, 54, 46, 0.2) inset;
  }
  75% {
    box-shadow: 20px -20px 50px 0px rgba(56, 54, 46, 0.3), 10px -10px 10px 0px #fff inset,
      -10px 10px 10px 0px rgba(56, 54, 46, 0.3) inset;
  }
  100% {
    box-shadow: 20px 20px 50px 0px rgba(56, 54, 46, 0.2),
      8px 8px 10px 0px rgba(255, 255, 255, 0.5) inset,
      -8px -8px 10px 0px rgba(56, 54, 46, 0.2) inset;
  }
}

@keyframes rotate-shapes {
  0% {
    transform: rotate(-75deg) translate(0, 0);
  }
  50% {
    transform: rotate(-65deg) translate(50px, 50px);
  }
  100% {
    transform: rotate(-75deg) translate(0, 0);
  }
}

@keyframes scale-shapes {
  0% {
    transform: scale(1) translate(0, 0);
  }
  50% {
    transform: scale(1.2) translate(42px, 42px);
  }
  100% {
    transform: scale(1) translate(0, 0);
  }
}

.page__content {
  overflow: auto;
  position: relative;
}

.page--share {
  .fund-section {
    padding-top: 0;
    &:last-child {
      padding-bottom: 0;
    }
  }
  .editor {
    padding-top: $spacing-5;
    box-shadow: none;
    &.fullsize {
      position: relative;
      height: auto;
    }
  }
  .support {
    max-width: none;
  }
}

@media (min-width: $grid-breakpoints-md) {
  .page--support {
    overflow: hidden;
    height: 100vh;
  }
  .page--share {
    min-height: 100%;
    height: auto;
    overflow: auto;

    .editor {
      padding-top: 110px;
    }
  }
}

.page--sidebar {
  .page__content {
    flex: 1 1 auto;
    padding: $spacing-5 $spacing-4;
    margin-left: 320px;
  }

  &.page--sidebar-small {
    .page__content {
      margin-left: 72px;
    }
  }
}

.page--imports {
  .page__content {
    margin-left: 0;
  }
}

.frozen-asset-entities {
  main {
    display: block;
    padding: 40px;
    min-height: 100vh;
  }
}

.frozen-asset-entities__landing {
  height: 100%;
  width: 700px;
  margin: auto;
}

.frozen-asset-entities__searchbar {
  width: 100%;

  .search-input-lg {
    width: 100%;
  }

  i {
    font-size: 24px;
  }

  input,
  button {
    font-size: $fts-large;
  }

  input {
    padding: $spacing-3 22px $spacing-3 3 * $spacing-3;
  }

  button {
    padding: $spacing-3;
  }
}

.frozen-asset-entities__separator {
  margin-left: -$spacing-5;
  margin-right: -$spacing-5;
}

.home {
  .container {
    height: 100vh;
  }
}
