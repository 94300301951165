/**
RECHARTS LEGEND OVERRIDE
 */
.recharts-legend-wrapper {
    ul {

        li {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            svg {
                display: block;
                width: 1rem;
                height: 1rem;
            }

            span {
                color: $grey;
                font-size: $fts-xs;
            }
        }
    }
}

/**
RECHARTS TOOLTIPS OVERRIDE
 */
.custom-tooltip {
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.64);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
    backdrop-filter: blur(5px);
    padding: 12px 12px 4px 12px;
    line-height: 1;

    .date {
        color: $grey;
        font-size: $fts-xs;
    }

    .aggregatedAmount,
    .amount {
        font-size: $fts-small;
        font-weight: 500;
    }
}

/**
PARTNERS
 */
.ace-partners {

    .partner-item {
        padding: 0 1.5rem;
        display: flex;
        gap: 12px;
        align-items: center;

        .partner-item__logo {
            overflow: hidden;
            border-radius: 4px;
            width: 40px;
            height: 40px;
            flex-shrink: 0;

            img {
                display: block;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .partner-item__broker {
            background-color: rgba(217, 205, 188, 0.4);
            color: #af9064;
            font-size: $fts-xlarge;
            font-weight: 700;
            width: 40px;
            height: 40px;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .partner-item__content {
            flex-grow: 1;
            flex-shrink: 1;
            font-weight: 400;
        }

        .partner-item__content-metas {
            display: flex;
            align-items: center;
        }

        .partner-item__content-range {
            position: relative;
            margin-top: 8px;
            width: 100%;
            border-radius: 4px;
            height: 6px;
            background-color: $blue-light;
        }

        .partner-item__metas {
            color: $grey;
            font-size: $fts-small;
        }

        .partner-item__amount {
            margin-left: auto;
            font-size: $fts-small;
        }

        .partner-item__range {
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 4px;
            background-color: $blue-900;
            height: 100%;
        }
    }
}