@mixin custom-commission-item($name, $margin-top) {
  .#{$name} {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    cursor: pointer;
    border-radius: 4px;
    padding: 12px;
    margin-top: $margin-top;

    &:hover {
      background-color: $neutral-main-hover;
    }

    .commission-label {
      @include body-m-medium;
      color: $neutral-main-text-secondary;
    }

    .commission-separator {
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex: 1;
      margin-left: 12px;
      margin-right: 12px;

      & > div {
        border-top: 1px solid $neutral-main-border;
      }
    }

    .commission-count {
      display: flex;
      flex-direction: row;
      align-items: center;

      @include body-m-regular;

      &.commission-count-error {
        color: $danger-text;
      }

      &.commission-count-success {
        color: $success-text;
      }

      &.commission-count-info {
        color: $neutral-main-text-tertiary;
      }

      & > i {
        line-height: 16px;
        padding: 6px;
      }
    }

    .commission-arrow {
      line-height: 16px;
      color: $neutral-main-text-tertiary;

      & > i {
        line-height: 16px;
        margin-left: 8px;
        padding: 6px;
      }
    }
  }
}
