[class^='icon-'],
[class*=' icon-'] {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-family: $ftf-icon;
  font-size: $fts-default;
  font-weight: 400;
  line-height: 1;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-style: normal;
  speak: none;
}

// icon size
.icon-32 {
  font-size: 32px;

  &.icon-box {
    width: 64px;
    height: 64px;
    line-height: 64px;
  }
}

.icon-28 {
  font-size: 28px;
}

.icon-24 {
  font-size: 24px;
}

.icon-20 {
  font-size: 20px;
}

.icon-16 {
  font-size: 16px;
}

.icon-12 {
  font-size: 12px;
}

.icon-box {
  background: rgba(0, 173, 181, 0.16);
  border-radius: 12px;
}

.icon-eye:before {
  content: '\e922';
}
.icon-angle-down:before {
  content: '\e921';
}
.icon-angle-right {
  transform: rotate(-90deg);
  &:before {
    content: '\e921';
  }
}
.icon-chevron-left {
  transform: rotate(180deg);
  &:before {
    content: '\e900';
  }
}
.icon-chevron-right:before {
  content: '\e900';
}
.icon-chevron-down {
  transform: rotate(90deg);
  &:before {
    content: '\e900';
  }
}
.icon-chevron-up {
  transform: rotate(-90deg);
  &:before {
    content: '\e900';
  }
}
.icon-circle:before {
  content: '\e901';
}
.icon-check-circle:before {
  content: '\e902';
}
.icon-search:before {
  content: '\e903';
}
.icon-star-filled:before {
  content: '\e904';
}
.icon-log-out:before {
  content: '\e905';
}
.icon-settings:before {
  content: '\e906';
}
.icon-arrow-left:before {
  content: '\e907';
}
.icon-arrow-right {
  transform: rotate(180deg);
  &:before {
    content: '\e907';
  }
}
.icon-external-link:before {
  content: '\e908';
}
.icon-star:before {
  content: '\e909';
}
.icon-trending-down:before {
  content: '\e90a';
}
.icon-trending-up:before {
  content: '\e90b';
}
.icon-download:before {
  content: '\e90c';
}
.icon-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 5px solid $color-primary-focus;
}
.icon-down {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-top: 5px solid $color-primary-focus;
}
.icon-pen-tool:before {
  content: '\e90d';
}
.icon-maximize:before {
  content: '\e90e';
}
.icon-minimize:before {
  content: '\e90f';
}
.icon-x-square:before {
  content: '\e910';
}
.icon-folder:before {
  content: '\e911';
}
.icon-more-horizontal:before {
  content: '\e912';
}
.icon-x:before {
  content: '\e913';
}
.icon-upload {
  transform: rotate(-90deg);
  &:before {
    content: '\e905';
  }
}
.icon-image:before {
  content: '\e914';
}
.icon-trash:before {
  content: '\e915';
}
.icon-copy:before {
  content: '\e916';
}
.icon-link:before {
  content: '\e917';
}
.icon-sliders:before {
  content: '\e918';
}
.icon-pie-chart:before {
  content: '\e919';
}
.icon-plus:before {
  content: '\e91a';
}
.icon-euro:before {
  content: '\e91b';
}
.icon-user:before {
  content: '\e91c';
}
.icon-file-text:before {
  content: '\e91d';
}
.icon-briefcase:before {
  content: '\e91e';
}
.icon-alert-circle:before {
  content: '\e91f';
}
.icon-check:before {
  content: '\e920';
}
