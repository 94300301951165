.navbar__content {
  padding: $spacing-4;
}

.navbar--ucits {
  .navbar__content {
    height: 100%;
    overflow-y: auto;
  }
}
