.btn {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  vertical-align: middle;
  height: 56px;
  min-width: 56px;
  line-height: 54px;
  border-radius: $radius-small;
  border: 1px solid $white-tertiary;
  font-size: $fts-small;
  font-weight: 500;
  transition: all 0.15s ease-out;
  cursor: pointer;
  color: $black;
  padding: 0 20px;
  outline: none;
  text-decoration: none;
  background-color: $white;

  &.is-disabled,
  &[disabled] {
    pointer-events: none;
    background: $white-secondary;
    color: $blue-200;
    opacity: 1;
    border: none;
  }

  &:hover,
  &:focus {
    color: $white;
    background-color: $color-secondary;
    outline: 0;
  }
}

.btn--small {
  height: 44px;
  line-height: 40px;
  padding: 0 $spacing-2;
  box-shadow: inherit;
}

.btn.btn--success {
  background-color: $green-turquoise;
}

.btn--full {
  width: 100%;
}

.btn--tertiary {
  background: transparent;
  border: 1px solid $blue-400;
  color: $white-quinary;
  box-shadow: none;
  &:hover,
  &:focus {
    border-color: $white-quinary;
  }
}
