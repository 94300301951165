html {
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black-secondary;
  font-family: $ftf-default, arial, sans-serif;
  font-size: $fts-default;
  line-height: 1.5;
  font-weight: 300;
  margin: 0;
  padding: 0;
  background-color: $white-secondary;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}
blockquote,
caption,
details,
dl,
figure,
hgroup,
label,
ol,
p,
pre,
td,
textarea,
th,
ul {
  margin: 0 0 $spacing-1;
}
body,
figure,
html,
label,
textarea,
dd {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 100;
  color: $black;
}

ol,
ul {
  padding-left: 0;
  margin-bottom: 0;
}

table {
  margin-bottom: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}
blockquote,
code,
img,
input,
pre,
table,
td,
textarea,
video {
  max-width: 100%;
}

img {
  height: auto;
}
div,
h1,
p,
table,
td,
textarea,
th {
  word-wrap: break-word;
  hyphens: none;
}

input,
select,
textarea {
  font-family: inherit;
  font-size: $fts-small;
  font-weight: 300;

  &:disabled {
    cursor: not-allowed;
  }
}

input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: none;
  font: inherit;
}

button {
  -webkit-appearance: none;
  background: none;
  border: 0;
  font: inherit;
  cursor: pointer;
}

img {
  vertical-align: middle;
}

a img {
  border: 0;
}
/* scripts */
body > script {
  display: none !important;
}
a {
  color: $color-primary;
  text-decoration: underline;
  cursor: pointer;
  transition: all 0.3s $timing-cubic;
  font-weight: 400;
}
ul {
  list-style: none;
}
nav {
  li {
    display: inline-block;
  }
}

figure {
  img,
  div {
    margin-bottom: $spacing-1;
  }
}

iframe {
  border: 0;
  overflow: hidden;
}

.clearfix:after {
  content: ' ';
  display: table;
  clear: both;
}
.clear {
  clear: both;
}
::placeholder {
  color: $color-primary;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $color-primary;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: $color-primary;
}
:-ms-input-placeholder {
  /* IE!)+ */
  color: $color-primary !important;
}
::-ms-input-placeholder {
  /* Edge */
  color: $color-primary;
}
