.commissions__aside {
  width: 300px;
  height: 100vh;
  border-right: 1px solid $white-quaternary;
  flex-shrink: 0;
}

.commissions__main {
  flex-grow: 1;
  height: 100vh;
}

.commissions__header {
  padding: $spacing-3 $spacing-4;

  h1 {
    font-size: 24px;
    line-height: 1.25em;
    font-weight: 400;
  }
}

.commissions--ucits {
  .commissions__content {
    padding: 0 0 $spacing-4 0;
    padding: 0 0 0 0;
    overflow: hidden;
  }
}

.commissions--container {
  padding-left: 0;
  padding-right: 0;
  max-width: unset;
}
