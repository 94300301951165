.tooltip {
  &.relative {
    position: relative;
    cursor: pointer;
  }

  .tooltip__content {
    position: absolute;
    right: 0;
    top: calc(100% + 4px);
    max-width: 270px;
    pointer-events: none;
    box-shadow: $shadow2;
    transform: translateY(-10px);
    opacity: 0;
    z-index: 0;
    visibility: hidden;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  .tooltip__content--show {
    z-index: 10;
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0px);
    visibility: inherit;
  }

  .btn.is-disabled {
    background-color: rgba($blue-200, 0.2);
  }
  .tooltip__item {
    position: relative;
    display: block;
    padding: $spacing-1 $spacing-2;
    transition: all 0.2s ease-in-out;
    text-align: left;

    &:hover {
      background-color: $white-tertiary;
      color: $blue-600;
    }
    &:not(:first-child) {
      padding-top: $spacing-1;
    }
  }
  .tooltip__item:not(:last-child)::before {
    content: '';
    position: absolute;
    left: 10%;
    right: 10%;
    bottom: 0;
    height: 1px;
    border-bottom: 1px solid $white-secondary;
    padding-bottom: $spacing-1;
  }
}

.tooltip--large {
  .tooltip__content {
    max-width: 380px;
    padding: $spacing-1 $spacing-2 $spacing-2;
  }
}

.tooltip--choice {
  .tooltip__content {
    padding: 0;
    span {
      padding: $spacing-2;
      cursor: pointer;

      &:not(:last-child),
      &:not(:first-child):not(:last-child) {
        border-right: 1px solid $white-secondary;
      }

      &:hover {
        background-color: $white-secondary;
      }
    }
  }
}

.tooltipV2 {
  position: relative;
  display: inline;
}

.tooltipV2__trigger {
  cursor: pointer;
}

.tooltipV2__content {
  pointer-events: none;
  top: 0;
  position: fixed;
  opacity: 0;
  white-space: normal;
  padding: $spacing-2;
  background: $white;
  z-index: 10;
  border-radius: $radius;
  box-shadow: $shadow2;
  transition: opacity 0.1s ease-out;
  max-width: calc(30vw);

  .tooltip--title {
    font-weight: 700;
    margin-bottom: 12px;
  }

  .tooltip--description {
    font-weight: 400;
  }
}

.tooltipV2__content--show {
  pointer-events: all;
  opacity: 1;
}
