.label {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 0 $spacing-1;
  height: 22px;
  line-height: 24px;
  font-size: $fts-xs;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: $white-tertiary;
  color: $blue-400;
  max-width: 200px;
  border-radius: $radius;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.is-active {
    background-color: $color-secondary;
    color: $white;
  }
}

.label--big {
  height: 36px;
  line-height: 36px;
  font-weight: 500;
  font-size: $fts-small;
  max-width: 100%;
  text-transform: none;
  letter-spacing: normal;
}

.label--full {
  width: 100%;
  text-align: center;
}

.label--green {
  background-color: $green;
  color: $white;
}

.label--red {
  background-color: $red;
  color: $white;
}

.label--red-secondary {
  color: $red;
  background-color: rgba($red, .16);
}

.label--blue {
  background-color: $color-secondary;
  color: $white;
}

  
.label--warning {
  background-color: $warning-default;
  color: $warning-text;
}

.label--error {
  background-color: $danger-default;
  color: $danger-text;
}