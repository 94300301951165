.heading {
  position: relative;
  z-index: 3;
  padding: 145px 0;
  text-align: center;

  h1 {
    max-width: 585px;
    line-height: 32px;
  }
}
