.table {
  display: block;
  overflow-x: auto;
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;

  thead {
    text-align: left;
    background: $white-tertiary;
    color: $blue-400;
  }

  .tr--hover {
    transition: background-color 0.2s $timing-cubic;
    cursor: pointer;
    &:hover {
      background-color: $white;
    }

    .icon-star-filled {
      position: relative;
      top: -2px;
    }
  }

  tr {
    border-top: 1px solid $white-tertiary;

    &:first-child {
      border-top: none;
    }

    td {
      height: 66px;
      vertical-align: middle;
    }
  }

  .tr--collapsed,
  .tr--collapsed > td {
    border-top: 0px;
    height: 0px;
    display: none;
    font-weight: 400 !important;
  }

  .tr--expanded,
  .tr--expanded > td {
    border-top: 0px;
    height: 50px;
    font-weight: 400 !important;
  }

  tr:last-of-type {
    border-bottom: 0px;
  }

  th {
    font-weight: 700;
    font-size: $fts-xs;
    padding: $spacing-2 0;
    border: 0;
    &:first-child {
      padding-left: $spacing-2;
    }
  }

  td,
  th {
    vertical-align: top;
  }

  td {
    color: $blue-400;

    &:first-child {
      padding-left: $spacing-2;
    }

    &.ft-truncat {
      max-width: 200px;
      padding-right: $spacing-1;
    }
  }
}

.table-commissions {
  overflow: visible;

  .table__group {
    height: 40px;
    background: $primary-variant-default;
    padding-left: 40px !important;

    span {
      color: $primary-variant-text-secondary-invert;
      text-transform: uppercase;

      &:first-of-type {
        color: $primary-variant-text-invert;
        text-transform: none;
      }
    }
  }

  .has-tooltip-error .icon-alert-circle {
    position: relative;
    top: -1px;
  }

  .has-error {
    color: $red !important;
  }

  .table {
    @include body-m-regular;
    background-color: $neutral-main-default;
    border: 1px solid $neutral-main-border;
    overflow: hidden;
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
    box-shadow: $shadow4;

    th,
    td {
      vertical-align: middle;
    }

    th {
      @include body-m-medium;

      background-color: $bg-inverted;
      color: $primary-variant-text-invert;
    }

    tr:hover,
    .tr--expand-details {
      background-color: $neutral-main-hover;
    }

    td {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    td:last-of-type {
      overflow: visible;
    }

    th:first-child,
    td:first-child {
      padding-left: $spacing-3;
    }

    th:last-child,
    td:last-child {
      padding-right: $spacing-3;
    }
  }

  .cell--expand {
    color: $neutral-main-text-quaternary;
    @include title-h2;
    padding-right: $spacing-1;
  }

  .cell--docket-header {
    @include body-m-regular;
    color: $neutral-main-text-secondary;
    text-transform: uppercase;

    & > span:first-of-type {
      @include body-m-medium;
      color: $primary-variant-text-invert;
      text-transform: none;
    }
  }

  .cell--docket-product {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $neutral-main-text-secondary;
    margin-bottom: 0px;
    max-width: 250px;
  }

  .cell--docket-direction {
    max-width: none;
  }

  .cell--docket-date {
    max-width: 100px;
  }

  // .cell--docket-direction {
  //   & > div {
  //     display: inline-block;
  //     vertical-align: middle;
  //   }
  // }

  .cell--docket-currency {
    @include body-m-medium;
    text-align: right;
    max-width: 80px;

    // & > div {
    //   display: inline-block;
    //   vertical-align: middle;
    // }
  }

  .cell--docket-percent {
    text-align: right;
    max-width: 80px;

    & > div {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .cell--docket-no-value > div {
    margin-right: 20px;
  }

  .cell--docket-collapse {
    text-align: right;
  }

  .cell--expand {
    width: 40px;
  }

  .cell--tooltip {
    width: 80px;
    text-align: right;
  }

  .cell--docket__warning {
    color: $warning-text;
  }

  .cell--docket__danger {
    color: $danger-text;

    .tooltipV2__content {
      text-align: left;
    }

    .icon-alert-circle {
      line-height: 24px;
    }
  }
}

.table-commissions__empty {
  min-height: 200px;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.table-commissions__filters {
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: $spacing-2;
}

.table-docket-modal-wrapper {
  height: 500px;
  overflow-y: scroll;
}

.table-docket-modal {
  thead {
    background-color: $white-secondary;
  }
  tr td {
    height: 48px;
  }
}

td[data-editable='true'] {
  input {
    padding: 0 $spacing-1;
  }
}

.cell-first {
  order: -1;
}

@media (min-width: 600px) {
  .table {
    display: table;
    overflow-x: hidden;
  }
}
