.input {
  position: relative;

  label {
    display: none;
  }

  input,
  textarea {
    display: block;
    width: 100%;
    height: 56px;
    padding: $spacing-1 22px $spacing-1 $spacing-2;
    background: $white;
    color: $black;
    border: 1px solid $white-quaternary;
    border-radius: $radius-small;
    -webkit-appearance: none;
    transition: border-color 0.2s $timing-cubic;

    &:focus,
    &:hover {
      border-color: $blue-200;
      outline: 0;
    }

    &:disabled {
      color: $neutral-main-text-tertiary;
      background-color: $neutral-main-default;
    }

    &::-ms-clear {
      display: none;
    }

    &::-webkit-input-placeholder {
      color: $blue-400;
    }

    &::-moz-placeholder {
      color: $blue-400;
    }

    &:-ms-input-placeholder {
      color: $blue-400 !important;
    }

    &::-ms-input-placeholder {
      color: $blue-400;
    }
  }

  input[type='file'] {
    height: auto;
  }

  textarea {
    height: auto;
    min-height: 100px;
  }

  &.has-error {
    input {
      border-color: $red;
    }
    ~ .input__error {
      position: inherit;
      display: block;
    }
    .icon-info {
      display: block;
    }
  }
  .icon-info {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 56px;
    line-height: 56px;
    font-size: 20px;
    display: none;
    color: $red;
    border-radius: 0 $radius $radius 0;
  }
}

.input--icons {
  position: relative;

  input[name='fileName'] {
    color: $blue-400;
  }

  input {
    padding-left: $spacing-5;
  }

  i {
    position: absolute;
  }

  i.icon--left {
    top: 20px;
    left: $spacing-2;
  }

  i.icon--right {
    top: 15px;
    right: $spacing-2;
    padding: 4px;
    border: 1px solid $blue-200;
    border-radius: $radius;
    cursor: pointer;
  }
}

.upload.is-hidden {
  position: absolute;
  visibility: hidden;
  height: 0;
}

.preferences input {
  max-width: 500px;
  min-width: 180px;
  width: 100%;
  float: left;
}

@media (min-width: $grid-breakpoints-md) {
  .input.has-error {
    .input__error {
      position: absolute;
    }
  }
}
