.card-reporting {
  padding: $spacing-2 $spacing-3;
  border-bottom: 1px solid $white-quaternary;
  cursor: pointer;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: $white;
  }

  img {
    width: 24px;
    height: 24px;
  }

  img + span {
    vertical-align: middle;
  }

  .icon-check {
    color: $turquoise;
  }
}

.card-reporting--disabled {
  cursor: initial;
  background: $white-tertiary;
  pointer-events: none;
}

.card-reporting--active {
  background-color: $white;
}

.card-reporting__delete {
  transition: transform 0.2s ease-out;

  &::before {
    color: $red;
  }

  &:hover {
    transform: scale(1.1);
  }
}
