
.tag {
  @include body-label-s;

  height: 24px;
  border-radius: 4px;
  justify-self: flex-end;
  vertical-align: middle;
  display: inline-block;
  padding-left: $spacing-1;
  padding-right: $spacing-1;
  line-height: 22px;
  white-space: nowrap;
  flex-shrink: 1;

  &.tag--default {
    color: $neutral-contrastText;
    background: $neutral-main;
  }
  
  &.tag--warning {
    color: $warning-contrastText;
    background: $warning-main;  
  }
  
  &.tag--danger {
    color: $error-contrastText;
    background: $error-main;  
  }
  
  &.tag--success {
    color: $success-contrastText;
    background: $success-main;  
  
    .icon-check {
      font-size: 14px;
      line-height: 24px;
      vertical-align: initial;
      font-weight: 700;
      margin-left: -3px;
      margin-right: -4px;
    }
  }
}

.tag--outlined {

  &.tag--default {
    color: $neutralLight-contrastText;
    background: $neutralLight-main;
  }
  
  &.tag--warning {
    color: $warningLight-contrastText;
    background: $warningLight-main;  
  }
  
  &.tag--danger {
    color: $errorLight-contrastText;
    background: $errorLight-main;  
  }
  
  &.tag--success {
    color: $successLight-contrastText;
    background: $successLight-main;  
  
    .icon-check {
      font-size: 14px;
      line-height: 24px;
      vertical-align: initial;
      font-weight: 700;
      margin-left: -3px;
      margin-right: -4px;
    }
  }
}


