$regular: 400;
$medium: 500;
$bold: 700;

/* Title */
@mixin title-h0 {
  font-family: $ftf-heading;
  font-weight: $regular;
  font-size: 68px;
  line-height: 76px;
}

@mixin title-h1 {
  font-family: $ftf-heading;
  font-weight: $regular;
  font-size: 48px;
}

@mixin title-h2 {
  font-family: $ftf-heading;
  font-weight: $regular;
  font-size: 24px;
  line-height: 32px;
}

@mixin title-h2b {
  font-family: $ftf-default;
  font-weight: $medium;
  font-size: 24px;
  line-height: 32px;
}

@mixin title-h3 {
  font-family: $ftf-heading;
  font-weight: $medium;
  font-size: 18px;
  line-height: 24px;
}

.h0, .title-h0 { @include title-h0; }
h1, .h1, .title-h1 { @include title-h1; }
h2, .h2, .title-h2 { @include title-h2; }
.h2b, .title-h2b { @include title-h2b; }
h3, .h3, .title-h3 { @include title-h3; }

/* Body */

@mixin body-xl-medium {
  font-family: 'basis';
  font-weight: $medium;
  font-size: 18px;
  line-height: 28px;
}

@mixin body-l-regular {
  font-family: 'basis';
  font-weight: $regular;
  font-size: 16px;
  line-height: 24px;
}

@mixin body-l-medium {
  font-family: 'basis';
  font-weight: $medium;
  font-size: 16px;
  line-height: 24px;
}

@mixin body-m-regular {
  font-family: 'basis';
  font-weight: $regular;
  font-size: 14px;
  line-height: 20px;
}

@mixin body-m-medium {
  font-family: 'basis';
  font-weight: $medium;
  font-size: 14px;
  line-height: 20px;
}

@mixin body-s-regular {
  font-family: 'basis';
  font-weight: $regular;
  font-size: 12px;
  line-height: 16px;
}

@mixin body-s-medium {
  font-family: 'basis';
  font-weight: $medium;
  font-size: 12px;
  line-height: 16px;
}

@mixin body-label-s {
  font-family: 'basis';
  font-weight: $bold;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

@mixin body-label-xs {
  font-family: 'basis';
  font-weight: $bold;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.body-xl-medium { @include body-xl-medium; }
.body-l-regular { @include body-l-regular; }
.body-l-medium { @include body-l-medium; }
.body-m-regular { @include body-m-regular; }
.body-m-medium { @include body-m-medium; }
.body-s-regular { @include body-s-regular; }
.body-s-medium { @include body-s-medium; }
.body-label-s { @include body-label-s; }
.body-label-xs { @include body-label-xs; }

/* Button */
@mixin button-l {
  font-family: 'basis';
  font-weight: $medium;
  font-size: 18px;
  line-height: 28px;
}

@mixin button-m {
  font-family: 'basis';
  font-weight: $medium;
  font-size: 16px;
  line-height: 24px;
}

@mixin button-s {
  font-family: 'basis';
  font-weight: $medium;
  font-size: 14px;
  line-height: 20px;
}

.button-l { @include button-l; }
.button-m { @include button-m; }
.button-s { @include button-s; }