.select {
  position: relative;
  overflow: hidden;
  color: $black;
  background: $white;
  min-width: 130px;

  &:after {
    content: '\e900';
    transform: rotate(90deg);
    position: absolute;
    right: $spacing-2;
    top: calc(50% - 9px);
    font-family: $ftf-icon;
    font-size: 20px;
    color: $blue-400;
    pointer-events: none;
    margin-top: -5px;
    text-align: center;
  }

  &:hover,
  &:focus {
    &:after {
      color: $color-primary;
    }
  }

  &.has-error {
    select {
      border-color: $red;
    }
  }

  &.is-selected {
    select {
      color: $black;
    }
  }

  select {
    display: block;
    width: 100%;
    height: 48px;
    box-shadow: none;
    background: none;
    -webkit-appearance: none;
    padding-left: $spacing-2;
    padding-right: $spacing-9;
    color: $black-secondary;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid $white-quaternary;
    border-radius: $radius-small;
    cursor: pointer;

    &:hover,
    &:focus {
      outline: 0;
      border-color: $blue-200;

      &:after {
        color: $color-primary;
      }
    }

    &::-ms-expand {
      display: none;
    }

    &:focus::-ms-value {
      background-color: transparent;
      color: $black;
    }

    &:disabled {
      background-color: #fcfcfc;
      pointer-events: none;
    }
  }

  &.is-dark {
    background: none;

    &.has-error {
      select {
        border-color: $red;
      }
    }

    &.is-selected {
      select {
        color: $white;
      }
    }

    &:after {
      color: $white;
    }

    select {
      color: $white;
      border-color: $white;

      &:focus::-ms-value {
        color: $white;
      }
    }
    option {
      color: $black;
    }
  }
}

.select--small {
  select {
    height: 40px;
    font-size: $fts-xs;
  }
}

.select--chevron {
  &:after {
    content: '\e901';
  }
}
