// is "mobile"
@media (max-width: $grid-breakpoints-md - 1) {
  .mobile-ban {
    &:not(&--force-display) {
      display: none;
    }
  }
  .mobile-only.mobile-only--force-hide {
    display: none;
  }
}

// is not "mobile"
@media (min-width: $grid-breakpoints-md) {
  .mobile-only {
    display: none;
  }
}

// is not "tablet"
@media (max-width: $grid-breakpoints-lg - 1) {
  .tablet-ban {
    &:not(&--force-display) {
      display: none;
    }
  }
  .tablet-only.tablet-only--force-hide {
    display: none;
  }
}

// is not "tablet"
@media (min-width: $grid-breakpoints-lg) {
  .tablet-only {
    display: none;
  }
}
