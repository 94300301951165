.expansion-panel__header {
  padding: $spacing-3;
  cursor: pointer;
  color: $blue-400;
  transition: color 0.2s ease-out;

  &:hover {
    color: $black;
  }
}

.expansion-panel__content {
  padding: 0 $spacing-3;
}

.expansion-panel--reporting {
  .expansion-panel__header {
    border-bottom: 1px solid $white-quaternary;
  }

  .expansion-panel__content {
    padding: 0;
  }

  &.expansion-panel--reporting-contract {
    .expansion-panel__header {
      padding: $spacing-3 2 * $spacing-3;
    }
  }

  &.expansion-panel--reporting-fee-type {
    .expansion-panel__header {
      padding: $spacing-3 3 * $spacing-3;
    }

    .expansion-panel__content {
      padding: 0 3 * $spacing-3 0 3 * $spacing-3;
    }

    &.expansion-panel--expanded {
      .expansion-panel__content {
        border-bottom: 1px solid $white-quaternary;
        padding-top: $spacing-5;
        padding-bottom: $spacing-5;
      }
    }
  }

  &.expansion-panel--reporting-insurance:last-of-type,
  &.expansion-panel--reporting-insurance:last-of-type
    .expansion-panel--reporting-contract:last-of-type,
  &.expansion-panel--reporting-insurance:last-of-type
    .expansion-panel--reporting-contract:last-of-type
    .expansion-panel--reporting-fee-type:last-of-type {
    &:not(.expansion-panel--expanded) {
      & > .expansion-panel__header {
        border-bottom: none;
      }
    }
  }
}

.expansion-panel--ucits {
  .expansion-panel__header {
    border-bottom: 1px solid $white-quaternary;
  }

  .expansion-panel__content {
    padding: 0;
  }
}
