/* Spacing */
$spacing-1: 8px;
$spacing-2: 16px;
$spacing-3: 24px;
$spacing-4: 32px;
$spacing-5: 40px;
$spacing-6: 48px;
$spacing-7: 56px;
$spacing-8: 64px;
$spacing-9: 72px;
$spacing-10: 80px;
$spacing-11: 110px;

/* Font familly */
$ftf-default: 'dm-sans'; // ok
$ftf-heading: 'dm-sans'; // ok
$ftf-icon: 'icons'; // ok

/* Font Size */
$fts-xlarge: 28px;
$fts-default: 16px;
$fts-large: 18px;
$fts-small: 14px;
$fts-xs: 12px;
$fts-xxs: 10px;

/* Basic colors*/
$red: #c84011; // ok
$turquoise: #00adb5; // ok
$blue: #3388e7; // ok
$green: #2ecc71;
$green-lagoon: #33e7b1; // ok
$green-turquoise: #68a7a3;
$orange: #cf6945;
$grey: #7a796e;

@function alpha($color, $percent) {
  @return transparentize($color, 1 - $percent);
}

/* Main colors */
$color-primary: #283846; // ok
$color-primary-focus: #31475a; // ok

$color-secondary: #375d7e; // ok
$color-tertiary: #2c3e50;

/* Black and white */
$black: #020a16; // ok
$black-secondary: #1b242e; // use for $blue-900: #1b2833;
$black-06: rgba($black, 0.06);

$white: #ffffff; // ok
$white-secondary: #f3f1ef; // ok
$white-tertiary: #e8e4df; // ok
$white-quaternary: #ded9d2; // ok
$white-quinary: #7a7370; // ok
$white-60: rgba($white, 0.6);
$white-80: rgba($white, 0.8);

/* Blue */
$blue-light: #dde2e8;
$blue-100: #e9eff6;
$blue-200: #b6bcc3; // ok
$blue-400: #5d6a7b; // ok
$blue-600: #354253; // ok
$blue-900: #1A4474;

/* Gold */
$gold-200: #d9cdbc;
$gold-400: #b39d85;
$gold-600: #89735a;

/* Border */
$light-border: #eeeeee;
$dark-border: rgba($white, 0.12);

/* Size */
$radius: 4px; // ok
$radius-small: 2px; // ok

/* Shadow */
$shadow1: 0px 4px 4px rgba(0, 0, 0, 0.25); // ok
$shadow2: 0 4px 8px rgba(0, 0, 0, 0.25);
$shadow3: 0 4px 8px rgba(0, 0, 0, 0.15);
$shadow4: 0 4px 8px 0px rgba(0, 0, 0, 0.1);

/* Transition */
$timing-cubic: cubic-bezier(0.25, 0.1, 0.25, 1); // ok

/* z-index */
$z-layers: (
  'overlay': 200,
  'has-overlay': 201,
  'header': 10,
  'wrapper-mobile': 2,
  'topbar': 3,
  'menu': 2,
  'menu-club': 4,
  'filter': 1,
  'cookie': 9,
);

@function z($layer) {
  @if not map-has-key($z-layers, $layer) {
    @warn "No z-index found in $z-layers map for `#{$layer}`. Property omitted.";
  }

  @return map-get($z-layers, $layer);
}

$sidebar-width: 320px;
