.checkbox,
.radio,
.switch {
  position: relative;
  color: $black-secondary;

  label {
    position: relative;
    display: inline-block;
    padding-left: $spacing-4;
    line-height: 16px;
    min-height: 24px;

    &:before {
      content: '';
      display: inline-block;
      height: 16px;
      width: 16px;
      vertical-align: middle;
      position: absolute;
      top: 1px;
      left: 0;
      border: solid 2px $blue;
    }

    &:after {
      content: '';
      position: absolute;
      top: 4px;
      left: 0;
    }
  }

  input {
    position: absolute;
    opacity: 0;

    &:disabled {
      ~ label {
        color: $blue;
      }
    }

    &:focus {
      ~ label {
        &:before {
          border-color: $blue;
        }
      }
    }
  }

  &.is-dark {
    color: $white-quaternary;

    label {
      &:before {
        border: solid 2px $light-border;
      }
    }

    input {
      &:focus {
        ~ label {
          &:before {
            border-color: $white;
          }
        }
      }
    }
  }
}

.checkbox {
  label {
    &:before {
      border-radius: $radius;
    }

    &:after {
      opacity: 0;
      transition: opacity ease-out 0.2s;
      will-change: opacity;
    }
  }

  input:checked {
    ~ label {
      &:after {
        content: '';
        width: 10px;
        height: 10px;
        margin-left: 3px;
        opacity: 1;
        background-color: $blue;
        border-radius: $radius-small;
      }
    }
  }

  &:hover {
    label {
      cursor: pointer;
    }
  }
}

.checkbox--link {
  label {
    width: 100%;
    &::before,
    &::after {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.checkbox-tag {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  cursor: pointer;

  label {
    display: block;
    cursor: pointer;
    padding: 8px 12px;
  }

  input {
    display: none;
  }
}

.radio {
  label {
    &:before {
      border-radius: 100%;
      background-color: $white;
      transition: border-color ease-out 0.2s;
      will-change: border-color;
    }

    &:after {
      opacity: 0;
      transition: opacity ease-out 0.2s;
      will-change: opacity;
    }
  }

  input:checked {
    ~ label {
      &:after {
        background-color: $color-primary;
        width: 8px;
        height: 8px;
        left: 3px;
        border-radius: 100%;
        opacity: 1;
      }
    }
  }

  &.is-dark {
    label {
      &:before {
        background-color: transparent;
      }
    }

    input:checked {
      ~ label {
        &:after {
          background-color: $white;
        }
      }
    }
  }
}

.switch {
  label {
    padding-left: $spacing-6;
    line-height: 24px;
    cursor: pointer;

    &:before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 40px;
      height: 12px;
      width: 31px;
      background-color: $white-quinary;
      border: 0;
      transition: background-color ease-out 0.2s;
      will-change: background-color;
    }

    &:after {
      background-color: $white;
      width: 18px;
      height: 18px;
      left: -2px;
      top: 3px;
      border-radius: 100%;
      transition: left ease-out 0.2s, transform $timing-cubic 0.2s;
      will-change: left;
      box-shadow: 0 2px 8px rgba(43, 75, 60, 0.2);
    }

    &.is-active {
      &:after {
        transform: translateX(18px);
      }
      &:before {
        background-color: $green-lagoon;
      }
    }
  }

  input {
    &:checked {
      ~ label {
        &:before {
          background-color: $color-primary;
        }

        &:after {
          top: 3px;
          left: 18px;
        }
      }
    }

    &:focus {
      ~ label {
        &:after {
          background-color: $color-primary;
          box-shadow: $white 0 0 0 8px inset, $black-secondary 0 3px 8px;
        }
      }
    }

    &.has-error {
      ~ label {
        &:before {
          background-color: $red;
        }
      }
    }
  }
}

.switch__info {
  display: block;
  margin-top: 1px;
  padding-left: $spacing-6;
}

.switch__error {
  color: $red;
}
