.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -$sidebar-width;
  padding: 0 $spacing-2;
  width: $sidebar-width;
  height: 100%;

  .icon-search,
  .icon-arrow-left,
  .icon-star-filled {
    position: relative;
    top: -1px;
  }

  .sidebar__link {
    text-decoration: none;
  }

  .sidebar-option {
    position: relative;
    margin: 0 (-$spacing-2);
    padding: $spacing-1 0 $spacing-1 $spacing-4;
    cursor: pointer;
    text-decoration: none;

    &::before {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 4px;
      background-color: $blue;
      content: '';
    }

    &:hover,
    &.is-active {
      color: $white;
      background-color: $color-primary-focus;
    }

    &.is-active {
      &::before {
        display: block;
      }
    }

    &.is-disabled {
      cursor: not-allowed;
      color: $blue-200;
      background-color: transparent;
      &::before {
        display: none;
      }
    }
  }

  .sidebar__backlink {
    position: relative;
    width: fit-content;
    cursor: pointer;
    transition: color 0.3s $timing-cubic;

    &:hover {
      color: $white;
    }
  }
}

.sidebar--black {
  background-color: $color-primary;
}

.sidebar--small {
  width: 72px;
}

.sidebar--extra-small {
  width: 72px;
  left: -72px;
}

.sidebar--small,
.sidebar--extra-small {
  .sidebar-icons {
    width: fit-content;
    margin: $spacing-5 auto 0 auto;

    a {
      text-decoration: none;
    }

    svg {
      display: block;
      margin: 0 auto $spacing-4 auto;
    }

    [class*='icon-'] {
      display: block;
      font-size: 20px;
      width: 40px;
      height: 40px;
      color: $blue-200;
      border-radius: $radius-small;
      padding-top: $spacing-1;
      transition: color 0.2s ease-out, background-color 0.2s ease-out;

      &:hover {
        color: white;
        background-color: #213b52;
      }
    }
    [class*='icon-'].is-active {
      color: white;
      background-color: #213b52;
    }
  }
}

@media (min-width: $grid-breakpoints-md) {
  .sidebar {
    left: 0;
    box-shadow: 4px 0 15px rgba(0, 0, 0, 0.15);

    + .page__content {
      margin-left: $sidebar-width;
    }
  }
  .sidebar--small {
    box-shadow: none;

    + .page__content {
      margin-left: 72px;
    }
  }
  .sidebar--extra-small {
    box-shadow: none;

    + .page__content {
      margin-left: 72px;
    }
  }
  .sidebar--no-shadow {
    box-shadow: unset;
  }
}
