.grid {
  display: flex;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.grid-row {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

@for $i from 1 to 12 {
  .grid-col-#{$i} {
    flex: #{$i / 12};
    padding-left: $spacing-1;
    padding-right: $spacing-1;
    display: flex;
    flex-direction: column;
  }
}

.grid-row-ai-start {
  align-items: flex-start;
}