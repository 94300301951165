.sidebar__profile {
  position: absolute;
  bottom: $spacing-2;
  left: $spacing-2;
  right: $spacing-2;
  cursor: pointer;

  .sidebar__profile__img {
    object-fit: cover;
    width: 32px;
    height: 32px;
    border: 1px solid $blue-200;
    border-radius: 100%;
  }

  .sidebar__profile__dropdown {
    position: absolute;
    bottom: 54px;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    background: $white-secondary;
    transition: all 0.2s $timing-cubic;

    &.is-active {
      opacity: 1;
      transform: translateY(-10px);
      visibility: visible;
    }

    a {
      text-decoration: none;
    }
  }

  .sidebar__profile__name {
    padding: $spacing-2 0;

    &.is-active {
      background: $white-secondary;
      color: $blue-600;
    }

    .icon-chevron-down {
      margin-left: $spacing-8;
    }
  }

  .icon-chevron-down {
    transition: transform 0.2s $timing-cubic;
  }

  .icon-chevron-down {
    &.is-active {
      color: $blue-600;
      transform: rotate(270deg);
    }
  }
}

.dropdown-option {
  padding: $spacing-2 0 $spacing-2 $spacing-2;
  transition: background-color 0.2s $timing-cubic;
  border-bottom: 1px solid $white-quaternary;

  &:hover {
    background-color: $white-tertiary;
  }
}

.sidebar--extra-small {
  .sidebar__profile__dropdown {
    width: 180px;
    box-shadow: $shadow2;
  }
}