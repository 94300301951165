.filter-dropdown {
  position: relative;
  display: inline-block;

  > .filter-dropdown__label {
    &:hover {
      color: $black;
    }
    &.is-light:hover {
      color: $white;
    }
  }
}

.filter-dropdown__label {
  cursor: pointer;
  &.is-light {
    color: white;
  }
  &.is-selected {
    ~ .filter-dropdown__content {
      display: block;
    }
  }

  svg {
    margin-left: $spacing-1;
    position: relative;
    top: -1px;
  }
}

.filter-dropdown__content {
  display: none;
  position: absolute;
  top: -13px;
  left: -17px;
  width: 350px;
  padding: $spacing-2;
  border: 1px solid $white-quaternary;
  box-shadow: $shadow2;
  z-index: 2;
}

.filter-dropdown__content--right {
  right: -17px;
  left: auto;
  text-align: right;
}

.filter-dropdown-custom {
  display: block;
  font-size: $fts-small;
  line-height: 38px;
  cursor: pointer;
}

.filter-dropdown-custom__value {
  display: block;
  width: 100%;
  height: 56px;
  border: 1px solid $white-quaternary;
  padding: $spacing-1 22px $spacing-1 $spacing-2;
  transition: border-color 0.2s $timing-cubic;
  border-radius: $radius-small;

  &:focus,
  &:hover {
    border-color: $blue-200;
    outline: 0;
  }

  .icon-chevron-right {
    line-height: inherit;
    transform: rotate(90deg);
  }
}

.filter-dropdown-custom.expanded {
  .icon-chevron-right {
    transform: rotate(-90deg);
  }
}

.filter-dropdown-custom__list .active {
  background-color: $white-tertiary;
}

.filter-dropdown-custom__list {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 1;
  margin-top: -2px;
  background-color: $white;
  border: 1px solid $white-quaternary;
  border-bottom-left-radius: $radius-small;
  border-bottom-right-radius: $radius-small;
  box-shadow: 0px 4px 8px $black-06;
}

.filter-dropdown-custom__list-item {
  padding: $spacing-1 $spacing-2;
  border-bottom: 1px solid $white-tertiary;
  &:hover {
    background-color: $white-tertiary;
  }
  &:last-child {
    border-bottom-color: transparent;
  }
}

.filter-dropdown-custom__list-item--disabled {
  background-color: $white;
  cursor: default;

  &:hover {
    background-color: $white;
  }
}

.filter-dropdown-custom--scrollable {
  .filter-dropdown-custom__list {
    height: 220px;
    overflow-y: scroll;
  }
}
