.filters-commission {
  display: none;
  background-color: $bg-00;
}

.filters-commission__search,
.filters-commission__year,
.filters-commission__kind {
  padding: $spacing-2 $spacing-3;
  border-bottom: 1px solid $white-quaternary;
}

@media (min-width: $grid-breakpoints-md) {
  .filters-commission {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 325px;
    min-width: 325px;
    min-height: 100vh;
    height: 100%;
    border-right: 1px solid $white-quaternary;

    h2 {
      text-align: left;
    }

    + .page__content {
      margin-left: 325px;
      flex-grow: 1;
    }

    + .page__empty-state {
      display: flex;
      min-height: 100vh;
      align-items: center;
      justify-content: center;
    }
  }
}

.mosaic-menu {
  padding-bottom: $spacing-2;
  clear: both;

  &.mosaic-menu-small {
    border-top: 1px solid $white-quaternary;
    border-bottom: 1px solid $white-quaternary;
    padding-top: $spacing-2;
    padding-left: $spacing-3;
    padding-right: $spacing-2;
  }

  .mosaic-menu-item {
    float: left;
    clear: none;
    margin-right: 10px;
  }

  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.filters-commission {
  .mosaic-menu {
    justify-content: space-between;
  }
}

.mosaic-menu-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid $neutral-main-border;
  border-radius: 3px;
  align-items: center;
  cursor: pointer;

  &.mosaic-menu-item-small {
    margin-top: $spacing-1;
    margin-bottom: $spacing-1;
    width: 75px;
    height: 60px;

    & > img {
      border-radius: 2px;
      width: 24px;
      min-height: 24px;
    }

    & > span {
      max-width: 72px;
      text-align: center;
      justify-content: center;
      color: $neutral-main-text-tertiary;
    }
  }

  &.mosaic-menu-item-large {
    margin: $spacing-2;
    width: 113px;
    height: 92px;

    & > img {
      border-radius: 2px;
      width: 40px;
      min-height: 40px;
    }

    & > span {
      max-width: 103px;
      letter-spacing: normal;
      text-align: center;
      justify-content: center;
      @include body-m-medium;
      text-transform: none;
      color: $neutral-main-text-tertiary;
    }
  }

  &.mosaic-menu-item--active {
    background-color: $neutral-main-default;
    border: 2px solid $primary-main-default;
  }
}

.sidebar-list {
  flex: 1;
  overflow-y: auto;
  scrollbar-width: none;
}

.sidebar-list-block {
  border-bottom: 1px solid $divider;
}

.sidebar-list-header {
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
  padding-right: 16px;
  background-color: $neutralLight-main;
  cursor: pointer;

  &:hover {
    background-color: $secondaryLight-main;
  }

  .sidebar-list-header-expand {
    .icon-chevron-up,
    .icon-chevron-down {
      line-height: 26px;
      vertical-align: inherit;
      color: $neutral-main-text-tertiary;
    }
  }

  .sidebar-list-header-caption {
    flex: 1;
    text-align: left;
    color: $neutral-main-text-primary;
  }
}

.sidebar-list-body {
  overflow: hidden;

  &.sidebar-list__collapsed {
    height: 0;
  }
}

.sidebar-list-item {
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 32px;
  padding-right: 16px;
  cursor: pointer;

  &:hover {
    background-color: $secondaryLight-main;
  }

  &.sidebar-list-item--active {
    background-color: $primaryLight-main;
    border-top: 1px solid $primary-main;
    border-bottom: 1px solid $primary-main;
  }

  .sidebar-list-item-caption {
    flex: 1;
    text-align: left;
    color: $text-secondary;
  }
}

.sidebar-empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  & > img {
    width: 24px;
    height: 44px;
  }

  & > div {
    @include body-m-regular;
    color: $text-secondary;
    margin: 24px;
  }
}

.label-docket-header {
  color: $text-secondary;
}

.img-logo-xl {
  width: 200px;
  height: 200px;
}
