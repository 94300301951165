@font-face {
  font-family: 'basis';
  src: url('../../fonts/basisgrotesquepro-regular.woff2') format('woff2'),
    url('../../fonts/basisgrotesquepro-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'basis';
  src: url('../../fonts/basisgrotesquepro-medium.woff2') format('woff2'),
    url('../../fonts/basisgrotesquepro-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'lora';
  src: url('../../fonts/lora-regular.woff2') format('woff2'),
    url('../../fonts/lora-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'icons';
  src: url('../../fonts/icons.eot?nozrbg');
  src: url('../../fonts/icons.eot?nozrbg#iefix') format('embedded-opentype'),
    url('../../fonts/icons.ttf?nozrbg') format('truetype'),
    url('../../fonts/icons.woff?nozrbg') format('woff'),
    url('../../fonts/icons.svg?nozrbg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'dm-mono';
  src: url('../../fonts/DMMonoCustom-Regular.woff2') format('woff2'),
    url('../../fonts/DMMonoCustom-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'dm-mono';
  src: url('../../fonts/DMMonoCustom-Medium.woff2') format('woff2'),
    url('../../fonts/DMMonoCustom-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'dm-sans';
  src: url('../../fonts/DMSans-Regular.woff2') format('woff2'),
    url('../../fonts/DMSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'dm-sans';
  src: url('../../fonts/DMSans-Medium.woff2') format('woff2'),
    url('../../fonts/DMSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'dm-sans';
  src: url('../../fonts/DMSans-Bold.woff2') format('woff2'),
    url('../../fonts/DMSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
