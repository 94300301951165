.loader-topbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  z-index: 1;

  &.is-loading {
    span {
      animation: 3s fakeload cubic-bezier(0.87, 0, 0.13, 1) forwards;
    }
  }

  &.is-loaded {
    span {
      animation: 1s fakeloadEnd cubic-bezier(0.7, 0, 0.84, 0) forwards,
        0.3s 1s disappear forwards;
    }
  }

  span {
    display: block;
    width: 100%;
    height: 4px;
    background-color: $blue;
    transform: translateX(-100%);
  }
}

@keyframes fakeload {
  to {
    transform: translateX(-40%);
  }
}

@keyframes fakeloadEnd {
  from {
    transform: translateX(-40%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes disappear {
  to {
    opacity: 0;
  }
}

.loader {
  span {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    margin-left: 5px;
    background: $color-primary;
    animation: 0.8s ease-in-out loading infinite;
    &:first-child {
      margin-left: 0;
      animation-delay: 0.1s;
    }
    &:nth-child(2) {
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      animation-delay: 0.3s;
    }
  }
  @keyframes loading {
    0% {
      opacity: 0;
      transform: translateY(0);
    }
    50% {
      opacity: 1;
      transform: translateY(-3px);
    }
    100% {
      opacity: 0;
      transform: translateY(0);
    }
  }
}

.loader-small {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: $blue-200;
  background: linear-gradient(
    to right,
    $blue-200 70%,
    rgba(255, 255, 255, 0) 40%
  );
  animation: loadingSmall 0.5s infinite linear;
  transform: translateZ(0);
  &:before {
    content: '';
    width: 50%;
    height: 50%;
    background: $blue-200;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
  }
  &:after {
    content: '';
    background: $white;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @keyframes loadingSmall {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
