.h0 {
  font-size: 68px;
  line-height: 76px;
}

h1,
.h1 {
  font-size: 48px;
  line-height: 1.25em;
}

h2,
.h2 {
  font-size: 28px;
  line-height: 33px;
}

h3,
.h3 {
  font-size: 24px;
  line-height: 1.25em;
}

h4,
.h4 {
  font-size: 22px;
  line-height: 1.25em;
}

h5,
.h5 {
  font-size: 20px;
  line-height: 1.25em;
}

h6,
.h6 {
  font-size: $fts-large;
  line-height: 1.25em;
}

// Copy
.ft-primary {
  font-size: $fts-large;
  line-height: 32px;
}

.ft-secondary {
  font-size: $fts-small;
  line-height: 20px;
}

.ft-tertiary {
  font-size: $fts-xs;
  line-height: 20px;
}

.ft-quaternary {
  font-size: $fts-xxs;
}

.ft-subtitle {
  font-size: $fts-xs;
  line-height: 15px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1.7px;
}

.ft-space {
  letter-spacing: 1px;
}

.ft-ellipsis {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inherit;
}

/*.ft-number {
  text-align: right;
  padding-right: 10px;
}*/

// Helper
.ft-up {
  text-transform: uppercase;
}
.ft-left {
  text-align: left;
}
.ft-center {
  text-align: center;
}
.ft-right {
  text-align: right;
}
.ft-italic {
  font-style: italic;
}
.ft-nowrap {
  white-space: nowrap;
}
.ft-underline {
  display: inline-block;
  border-bottom: 1px solid currentColor;
}

.ft-100 {
  font-weight: 100;
}
.ft-300 {
  font-weight: 300;
}
.ft-400 {
  font-weight: 400;
}
.ft-500 {
  font-weight: 500;
}
.ft-700 {
  font-weight: 700;
}

.ft-truncat {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ft-heading {
  font-family: $ftf-heading;
}

/* Colors */

.c-black {
  color: $black;
}
.c-black-secondary {
  color: $black-secondary;
}

.c-white {
  color: $white;
}
.c-white-secondary {
  color: $white-secondary;
}
.c-white-tertiary {
  color: $white-tertiary;
}
.c-white-quaternary {
  color: $white-quaternary;
}
.c-white-quinary {
  color: $white-quinary;
}
.c-white-60 {
  color: $white-60;
}

.c-white-80 {
  color: $white-80;
}

.c-red {
  color: $red;
}
.c-orange {
  color: $orange;
}

.c-blue {
  color: $blue;
}
.c-blue-200 {
  color: $blue-200;
}
.c-blue-400 {
  color: $blue-400;
}
.c-blue-600 {
  color: $blue-600;
}
.c-blue-light {
  color: $blue-light;
}
.c-green-lagoon {
  color: $green-lagoon;
}
.c-green-turquoise {
  color: $green-turquoise;
}
.c-turquoise {
  color: $turquoise;
}

.c-gold-200 {
  color: $gold-200;
}
.c-gold-400 {
  color: $gold-400;
}
.c-gold-600 {
  color: $gold-600;
}


.cursor-p {
  cursor: pointer;
}