.card-sidebar {
  display: block;
  position: relative;
  padding: $spacing-2;
  border: 1px solid $blue-400;
  border-radius: $radius;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
  transition: background-color 0.2s $timing-cubic;
  text-decoration: none;
  &:hover {
    background-color: $color-primary-focus;
    cursor: pointer;
  }

  &.is-disabled {
    pointer-events: none;
    cursor: not-allowed;
    background: #24303B;

    > * { opacity: .5; }
    .label { opacity: 1; }
  }

  .icon-chevron-right {
    position: absolute;
    top: 21px;
    right: $spacing-2;
  }

  .card-sidebar__title {
    max-width: 90%;
  }
}
